import { PageHeader } from '@ant-design/pro-components';
import { Button, Col, Divider, Form, Input, Row, Select, Switch, Table, Tag, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { ProductProjectListResponseModel } from '@uniquegood/realworld-admin-interface/dist';
import { ColumnsType } from 'antd/es/table';
import { debounce } from 'es-toolkit';
import toast from 'react-hot-toast';
import { QuestionCircleOutlined } from '@ant-design/icons';
import DefaultLayout from '@src/components/DefaultLayout';
import { productApi } from '@src/apis/admin';
import { useGetAdvertisementProjects } from '@src/apis/admin/queries';
import { usePatchAdvertisementProject } from '@src/apis/admin/mutations';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

const PAGE_SIZE = 30;

export default function AdvertisementProjectList() {
  const [tempList, setTempList] = React.useState<{ id: string; name: string }[]>();
  const [input, setInput] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);

  const { data: advertisementProjects, isLoading: isAdvertisementProjectsLoading } = useGetAdvertisementProjects({
    page: currentPage,
    take: PAGE_SIZE,
    viewAdvertisement: undefined,
    viewBannerAdvertisement: undefined,
    keyword: input
  });
  const { mutateAsync: patchAdvertisementProject } = usePatchAdvertisementProject();

  const handleKeywordChange = debounce((value: string) => {
    setCurrentPage(1);
    setInput(value);
  }, 500);
  const handleAddClick = async () => {
    try {
      await productApi.editProductIsViewAd({
        projectIds: tempList && tempList.length > 0 ? tempList.map((item) => item.id) : null,
        isViewAd: false
      });

      message.success('광고 시청 여부 변경 완료되었습니다.');
      setTempList(undefined);
    } catch (e) {
      console.error(e);
      message.success('오류가 발생해 시청 여부 변경에 실패했습니다.');
    }
  };

  const adFreeProjectColumns: ColumnsType<ProductProjectListResponseModel> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '프로젝트 명'
    },
    {
      key: 'projectId',
      dataIndex: 'projectId',
      title: '프로젝트 ID'
    },
    {
      key: 'viewAdvertisement',
      dataIndex: 'viewAdvertisement',
      title: (
        <div>
          광고 시청 여부{' '}
          <Tooltip title="게임 시작 전 광고를 시청해야하는지 여부를 설정합니다.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      render: (_, record) => (
        <Switch
          checked={record.isViewAd}
          onChange={async (value) => {
            try {
              await patchAdvertisementProject({
                projectIds: record.projectId ? [record.projectId] : [],
                isViewAdvertisement: value,
                isViewBannerAdvertisement: record.isViewBannerAd || false
              });

              toast.success('광고 시청 여부를 변경했습니다');
            } catch (e) {
              console.error(e);
              toast.error('광고 시청 여부 변경에 실패했습니다');
            }
          }}
        />
      )
    },
    {
      key: 'viewBannerAdvertisement',
      dataIndex: 'viewBannerAdvertisement',
      title: (
        <div>
          배너 광고 시청 여부{' '}
          <Tooltip title="게임 플레이 하단에 배너 광고를 노출하는지 여부를 설정합니다.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      render: (_, record) => (
        <Switch
          checked={record.isViewBannerAd}
          onChange={async (value) => {
            try {
              await patchAdvertisementProject({
                projectIds: record.projectId ? [record.projectId] : [],
                isViewAdvertisement: record.isViewAd || false,
                isViewBannerAdvertisement: value
              });

              toast.success('배너 광고 시청 여부를 변경했습니다');
            } catch (e) {
              console.error(e);
              toast.error('배너 광고 시청 여부 변경에 실패했습니다');
            }
          }}
        />
      )
    }
  ];

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  return (
    <DefaultLayout>
      <PageHeader
        title="게임 광고 관리"
        subTitle="각 게임의 광고 시청 여부를 설정합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Form style={{ width: '100%' }}>
          {tempList && tempList.length > 0 && (
            <Row key="test2" gutter={16} style={{ width: '50%', marginBottom: '16px' }}>
              <Col flex={1}>
                <Select
                  mode="multiple"
                  showSearch={false}
                  notFoundContent={null}
                  tagRender={tagRender}
                  onChange={(value) =>
                    setTempList((prev) => prev?.filter((item) => (value as unknown as string[]).includes(item.id)))
                  }
                  value={tempList?.map((item) => ({ value: item.id, label: item.name }))}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col>
                <Button onClick={handleAddClick} type="primary">
                  추가하기
                </Button>
              </Col>
            </Row>
          )}
          <Divider />
          <Row key="test3" style={{ width: '50%', marginBottom: '16px' }}>
            <Input
              onChange={(e) => {
                handleKeywordChange(e.target.value);
              }}
              placeholder="프로젝트를 검색해보세요"
            />
          </Row>
        </Form>

        <Table
          rowKey="id"
          dataSource={advertisementProjects?.data?.filter((item) => item.name?.trim().includes(input))}
          columns={adFreeProjectColumns}
          loading={isAdvertisementProjectsLoading}
          pagination={{
            total: advertisementProjects?.count,
            pageSize: PAGE_SIZE,
            onChange: (page) => {
              setCurrentPage(page);
            }
          }}
        />
      </Content>
    </DefaultLayout>
  );
}
