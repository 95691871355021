import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ClueCategory, SubPageRequestModel } from '@uniquegood/realworld-admin-interface';
import { productApi, subPageApi } from '.';

export const usePatchSubPage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['patchSubPage'],
    mutationFn: async ({
      id,
      category,
      body
    }: {
      id: string;
      category?: ClueCategory;
      body: SubPageRequestModel[];
    }) => {
      const { data } = await subPageApi.updateSubPageDetail(id, category, body);

      return data;
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: ['getSubPageDetail', id] });
    }
  });
};

export const usePatchAdvertisementProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['patchAdvertisementProject'],
    mutationFn: async ({
      projectIds,
      isViewAdvertisement,
      isViewBannerAdvertisement
    }: {
      projectIds: string[];
      isViewAdvertisement: boolean;
      isViewBannerAdvertisement: boolean;
    }) => {
      const { data } = await productApi.editProductIsViewAd({
        projectIds,
        isViewAd: isViewAdvertisement,
        isViewBannerAd: isViewBannerAdvertisement
      });

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAdvertisementProjects'] });
    }
  });
};
